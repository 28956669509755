







































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { WorkPlatformService } from '@WorkPlatform/services';
import { isPC, isPhone, getImageUrl } from '@WorkPlatform/common/utils';
import { CommonServices } from '@WorkPlatform/common/service';

@Component({
  name: 'app-page',
  components: {
  },
})
export default class ComponentName extends Vue {
  @Prop({ default: [] }) details!: any;

  @Prop({ default: '1' }) todoType!: string;

  get IsDingTalk () {
    return sessionStorage.IS_DINGTALK === 'true';
  }

  get ISWECHAT () {
    return sessionStorage.IS_WECHAT === 'true';
  }

  getImgUrl (img) {
    return getImageUrl(img);
  }

  statusImageMap (status: string) {
    try {
      let name = '';
      switch (status) {
        case '1':
          name = 'agree';
          break;
        case '2':
          name = 'reject';
          break;
        case '3':
          name = 'forward';
          break;
        case '4':
          name = 'cancel';
          break;
        case '5':
          name = 'undo';
          break;
        default:
          break;
      }
      return require(`../../../assets/images-m/${name}.png`);
    } catch (e) {
      return '';
    }
  }

  /**
   * 跳转待办详情
   */
  clickHandler (item: any) {
    const params: any = {
      appCode: item.appCode,
      appVersion: item.appVersion,
    };

    WorkPlatformService.getAppUrl(params).then((res: any) => {
      if (res.success) {
        let pageArr: any[] = [];

        try {
          pageArr =
            typeof item.pageJson === 'string' ? JSON.parse(item.pageJson) : [];
        } catch (e) { }

        let pageJson: any;

        for (const page of pageArr) {
          if (page.pageType !== 'ALL') {
            if (isPC() && page.pageType === 'PC') {
              pageJson = page;
              break;
            } else if (isPhone() && page.pageType === 'MOBILE') {
              if (page.appCode) {
                pageJson = page;
              } else {
                for (const pageM of pageArr) {
                  if (pageM.pageType === 'PC') {
                    pageJson = pageM;
                    break;
                  }
                }
              }
              break;
            } else {
              pageJson = page;
            }
          } else {
            pageJson = page;
            break;
          }
        }

        let query: any = {
          groupCode: '',
          pageGroupCode: '',
          pageCode: '',
          workItemId: item.id,
          workflowInstanceId: item.instanceId,
          appVersion: item.appVersion,
          isFromTask: true,
          ...pageJson,
        };
        query = CommonServices.setLoginParam(query);
        const str: string = Object.entries(query)
          .map((queryArr: any[]) => {
            return queryArr.join('=');
          })
          .join('&');

        const url = `${res.data}?${str}`;

        try {
          window.history.pushState({}, '');
          window.location.replace(url);
        } catch (e) {
          Vue.prototype.$errorcatcher.showMessagePopup({
            title: '错误提示！',
            message: [e, '!'],
            displayFooter: false,
            correctGuide: '请认真检查浏览器是否开启禁止新开弹窗！',
          });
        }
      }
    });
  }

  formatSeconds (value: any): string {
    if (!value) return '--';
    let theTime: number = parseInt(value, 10); // 需要转换的时间秒
    let theTime1: number = 0; // 分
    let theTime2: number = 0; // 小时
    let theTime3: number = 0; // 天

    if (theTime > 60) {
      theTime1 = parseInt(String(theTime / 60), 10);
      theTime = parseInt(String(theTime % 60), 10);
      if (theTime1 > 60) {
        theTime2 = parseInt(String(theTime1 / 60), 10);
        theTime1 = parseInt(String(theTime1 % 60), 10);
        if (theTime2 > 24) {
          // 大于24小时
          theTime3 = parseInt(String(theTime2 / 24), 10);
          theTime2 = parseInt(String(theTime2 % 24), 10);
        }
      }
    }
    let result = '';
    if (theTime > 0 && theTime2 <= 0) {
      result = '' + parseInt(theTime + '', 10) + '秒';
    }
    if (theTime1 > 0) {
      result = '' + parseInt(theTime1 + '', 10) + '分' + result;
    }
    if (theTime2 > 0) {
      result = '' + parseInt(theTime2 + '', 10) + '小时' + result;
    }
    if (theTime3 > 0) {
      result = '' + parseInt(theTime3 + '', 10) + '天' + result;
    }

    return result;
  }

  timeOut (value: string) {
    const oldTime: any = new Date(value);
    const newTime: any = new Date();
    const timeout: any = parseInt((newTime - oldTime) + '', 10);
    return this.formatSeconds(timeout / 1000);
  }

  /**
   * 展示人员信息
   */
  showUserCard () {
    this.$emit('showUserCard', this.details.originator);
  }
}
